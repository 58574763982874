<div class="modal-header">
  <h4>{{'MODALS.PLEASE_CONFIGURE_YOUR_ACCOUNT_TITLE' | translate}}</h4>
</div>

<div class="modal-body row">
  <p>{{'MODALS.PLEASE_CONFIGURE_YOUR_ACCOUNT_MESSAGE' | translate}}</p>
</div>

<div class="modal-footer"
     align="end">
  <button (click)="openProfile()"
          class="m-btn btn btn-primary">
    <span>{{ "ACTIONS.CONFIGURE" | translate }}</span>
  </button>
</div>
