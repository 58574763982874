<form [formGroup]="form"
      class="mt-3 ml-1 mr-1"
      style="min-width: 200px;">
  <ng-select *ngIf="this.allowedTenants?.length>1"
             formControlName="tenantId"
             #ngSelect
             (ngModelChange)="cdRef.detectChanges()"
             [openDelay]="300"
             placement="bottom"
             class="form-control m-input"
             bindValue="id"
             bindLabel="displayName"
             [clearable]="false"
             [virtualScroll]="true"
             dropdownPosition="bottom"
             [items]="allowedTenants">
  </ng-select>
</form>
