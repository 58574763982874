export enum TenantPermissions {
  //Permission to access Fordes
  CanPerformFordesk = "can_perform_fordesk",
  //permission to access tenant on the CP side
  CanPerformClientPortal = "can_perform_client_portal",

  CanPerformSynchronization = "can_perform_synchronization",
  CanPerformFinanceExtension = "can_perform_finance_extension",
  CanPerformClientPortalConfig = "can_perform_client_portal_config",
  CanPerformMasterdata = "can_perform_masterdata",
  CanPerformNotifications = "can_perform_notifications",
  CanPerformProspects = "can_perform_prospects",
  CanPerformSales = "can_perform_sales",
  CanPerformTemplatesMgtAdmin = "can_perform_templates_mgt_admin",
  CanPerformAdmin = "can_perform_fordesk_admin",
  CanPerformFinancial = "can_perform_financial",//Financial user
  CanPerformQuote = "can_perform_quote",
  CanPerformReporting = "can_perform_reporting",
  CanPerformUserManagement = "can_perform_user_management",
  CanPerformOcr = "can_perform_ocr",
}
