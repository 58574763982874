export const enums_locale = {

  OPERATIONAL_FILE_TYPE: {
    IMPORT: "Import", // 0
    EXPORT: "Export", // 1
    CROSSTRADE: "CrossTrade", // 2
    WHS: "Warehousing (WHS)", // 3
    WIMPORT: "Import + WHS", // 0
    WEXPORT: "Export + WHS", // 1
    WCROSSTRADE: "CrossTrade + WHS"// 2
  },
  TRANSPORT_TYPE: {
    SEA: "Sea", // 0
    AIR: "Air", // 1
    RAIL: "Rail", // 2
    ROAD: "Road"// 3
  },
  SHIPMENT_TYPE: {
    FCL: "FCL", // 0
    LCL: "LCL", // 1
    BREAKBULK: "Breakbulk", // 2
    RORO: "RoRo", // 3
    FTL: "FTL", // 4
    LTL: "LTL", // 5
    HAULAGE: "Haulage"// 6
  },
  SUPPLIER_COMUNICATION_TYPE: {
    INTTRA: "INTTRA", // 0
    EDI: "EDI", // 1
  },

  TENANT_RELATIONS: {
    TITLE: {
      FORDESK_ADMIN: "Fordesk Administrator",
      FORDESK_USER: "Fordesk User",
      FINANCE_EXTENSION: "Finance Extension User",
      CLIENT_PORTAL_USER: "Client Portal User",
      CLIENT_PORTAL_CONFIG: "Client Portal Management",
      MASTERDATA: "Master Data User",
      NOTIFICATIONS: "Notifications User",
      PROSPECTS: "Prospects User",
      TEMPLATES_MGT_ADMIN: "Templates Management User",
      FINANCIAL_USER: "Finance User",
      QUOTE_USER: "Quote User",
      REPORTING_USER: "Reporting User",
      USER_MANAGEMENT: "User Management",
      OCR_USER: "OCR User",
    },
    DESCRIPTION: {
      FORDESK_ADMIN: "Allows managing dashboards, synchronization and integrations",
      FORDESK_USER: "Allows access of users to the Fordesk application",
      FINANCE_EXTENSION: "Allows access to functionality regarding saving unpaid Invoice documents, importing coda files, exporting invoices, creating outgoing invoice documents",
      CLIENT_PORTAL_USER: "Allows access to the ClientPortal application",
      CLIENT_PORTAL_CONFIG: "Allows managing customers for client portal users",
      MASTERDATA: "Allows access to Masterdata",
      NOTIFICATIONS: "Allows access to notifications",
      PROSPECTS: "Allows access to Sales->Prospects",
      TEMPLATES_MGT_ADMIN: "Allows access to the templates management application",
      FINANCIAL_USER: "Allows access Finance",
      QUOTE_USER: "Allows access to Sales->Quotes",
      REPORTING_USER: "Allows access to Reporting",
      OCR_USER: "Allows access OCR functionality",
      USER_MANAGEMENT: "Allows management of users"
    }
  }
};

