import { B2CAuthConfig } from "./abstraction/b2cAuthConfig";
import { EnvironmentConfig } from "./abstraction/environmentConfig";

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const authentication: B2CAuthConfig = {
  authFlow: "#{authFlow}#",
  authClientId: "#{authClientId}#",
  userManagementScope: "#{userManagementScope}#",
  userManagementApiUrl: "#{userManagementApiUrl}#",
  tenant: "#{tenant}#",
  fordeskApiScope: "#{fordeskApiScope}#",
  postLogoutRedirectUri: `${window.location.origin}`,
  redirectUrl: `${window.location.origin}/dashboards`,
}

export const environment: EnvironmentConfig = {
  syncfusionAspNetCoreLicenseKey: "#{SyncfusionAspNetCoreLicenseKey}#",
  production: false,
  authentication: authentication,
  name: "STAGING",
  integrationSettings: {
    isCustomsEnabled: true,
    isCreateOutgoingInvoiceEnabled: true,
  },
  notificationsRefreshRate: 60000,
  applicationUrl: "https://staging.fordesk.net",
  clientApiConfig: {
    host: "https://staging.fordesk.net",
    baseUrl: "api"
  },
  identityApiConfig: {
    host: "https://security-staging.fordesk.net",
    baseUrl: "api"
  },
  forDeskApiConfig: {
    host: "https://api-staging.fordesk.net",
    baseUrl: "api"
  }
};
